export const generateLinks = (message: string) => {
  const url = window.location.href
  const textMessage = `${message}:\n\n${url}`

  const mailToLink = `mailto:?body=${encodeURI(textMessage)}`
  const facebookLink = encodeURI(`https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${message}`)
  const twitterLink = encodeURI(`https://twitter.com/intent/tweet?text=${message}&url=${url}`)
  const whatsAppLink = encodeURI(`https://api.whatsapp.com/send?text=${textMessage}`)

  return { mailToLink, facebookLink, twitterLink, whatsAppLink }
}

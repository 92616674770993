import { OrListFragment, OrRecipeFragment } from './fragments'
import { BaseBlock, BlockType, ClientSideRecipeList } from './contentfulTypes'

export interface QueryRecipeListResult {
  orList: {
    blockType: BlockType.RECIPE
    itemsCollection: {
      items: ClientSideRecipeList[]
    }
  } & BaseBlock
}

export default `
  query RecipeListQuery($listId: String!) {
    orList(id: $listId) {
      ...OrListFragment
      itemsCollection {
        items {
          ...OrRecipeFragment
        }
      }
    }
  }
  ${OrListFragment}
  ${OrRecipeFragment}
`

/* eslint-disable react/no-danger */
import React from 'react'
import { toCssPrefix, Button, breakpoints } from '@ecommerce/shared'
import styled from 'styled-components'
import InfoTitle from './InfoTitle'
import { Icon } from '../Icon/Icon'

type Props = {
  onShareClick: () => void
  data: {
    aboutTitle: string
    aboutText: string
    ingredients: string[]
    ingredientsTitle: string
    preparationTitle: string
    preparationText: string
  }
}

const { cssPrefix, toPrefix } = toCssPrefix('RecipeInfo__')

const Wrapper = styled.div`
  .${cssPrefix} {
    &info-item {
      margin-bottom: 30px;

      &-text {
        font-size: 17px;
        line-height: 27px;
        margin: 0;
      }

      &-list {
        padding-left: 0;
        margin: 0;
        list-style: none;

        li {
          font-size: 18px;
          margin-bottom: 15px;
          position: relative;
          padding-left: 18px;

          &::after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: calc(50% - 5px);
            background: ${({ theme }) => theme.colors.bodyText};
            width: 8px;
            height: 8px;
            border-radius: 50%;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &share-button {
      width: 155px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 16px;
      padding-left: 16px;

      span {
        margin-bottom: -1px;
      }

      &-icon {
        width: 20px;
        height: 20px;
      }
    }

    @media screen and (${breakpoints.tabletLandscape.min}) {
      &info-item {
        &-list {
          display: grid;
          grid-template-columns: repeat(2, 50%);
        }
      }
    }

    @media screen and (${breakpoints.desktop.min}) {
      &share-button {
        position: absolute;
        right: 0;
        top: 125px;
        z-index: 1;
      }
    }
  }
`

const RecipeInfo = ({ data, onShareClick }: Props) => {
  const { aboutTitle, aboutText, ingredients, ingredientsTitle, preparationTitle, preparationText } = data

  return (
    <Wrapper className={cssPrefix}>
      <div className={toPrefix('info-item')}>
        <InfoTitle iconId="cocktail" title={aboutTitle} />
        <p className={toPrefix('info-item-text')}>{aboutText}</p>
      </div>
      <div className={toPrefix('info-item')}>
        <InfoTitle iconId="ingredients" title={ingredientsTitle} />
        <ul className={toPrefix('info-item-list')}>
          {ingredients.map((ingredient, i) => (
            <li key={i} dangerouslySetInnerHTML={{ __html: ingredient }} />
          ))}
        </ul>
      </div>
      <div className={toPrefix('info-item')}>
        <InfoTitle iconId="mix" title={preparationTitle} />
        <p className={toPrefix('info-item-text')}>{preparationText}</p>
      </div>
      <Button onClick={onShareClick} btnColor="gray" className={toPrefix('share-button')}>
        <Icon className={toPrefix('share-button-icon')} iconId="arrow_up" fillColor="black" />
        <span>Compartir</span>
      </Button>
    </Wrapper>
  )
}

export default RecipeInfo

import React from 'react'
import { toCssPrefix, Image, breakpoints } from '@ecommerce/shared'
import styled from 'styled-components'
import Slider from '../FramerSlider'
import { ContentfulImage } from '../../graphql/contentfulTypes'

type Props = {
  steps: string[]
  images: ContentfulImage[]
}

const { cssPrefix, toPrefix } = toCssPrefix('RecipeSteps__')
const { cssPrefix: slidePrefix, toPrefix: toSlidePrefix } = toCssPrefix('RecipeStepSlide__')

const Wrapper = styled.div`
  height: 100%;
  width: 100%;

  .${cssPrefix} {
    &slider,
    &slider-inner {
      height: 100%;
    }

    &slider-bullets {
      position: relative;
      top: -30px;
    }
  }

  @media screen and (${breakpoints.tabletLandscape.min}) {
    .${cssPrefix} {
      &slider-nav {
        display: flex;

        &.next {
          right: 30px;
        }

        &.prev {
          left: 30px;
        }
      }
    }
  }
`

const Slide = styled.div`
  width: 100vw;
  height: 100%;
  z-index: 0;
  position: relative;
  padding: 45px 35px;
  color: ${({ theme }) => theme.colors.brandText.default};
  font-weight: bold;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  box-shadow: ${({ theme }) => theme.boxShadow.lvlTwo};

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
    z-index: -1;
    border-bottom-left-radius: inherit;
    border-top-left-radius: inherit;
  }

  .${slidePrefix} {
    &image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      border-bottom-left-radius: inherit;
      border-top-left-radius: inherit;
    }

    &step,
    &count {
      margin: 0;
    }

    &count {
      font-size: 32px;
      letter-spacing: 5px;
    }

    &step {
      font-size: 18px;
    }
  }

  @media screen and (${breakpoints.tabletLandscape.min}) {
    padding: 45px 55px;
  }

  @media screen and (${breakpoints.desktop.min}) {
    width: calc(50vw - 80px);
    max-width: 680px;
    padding: 45px 35px;
    border-bottom-left-radius: ${({ theme }) => theme.borderRadius};
    border-top-left-radius: ${({ theme }) => theme.borderRadius};
  }
`

const RecipeSteps = ({ images, steps }: Props) => {
  return (
    <Wrapper className={cssPrefix}>
      <Slider useBullets className={toPrefix('slider')} itemsCount={images.length}>
        {images.map(({ file: { url } }, i) => (
          <Slide key={url} className={slidePrefix}>
            <Image
              className={toSlidePrefix('image')}
              alt={steps[i]}
              src={url}
              params={{
                fm: 'jpg',
                q: 80,
              }}
            />
            <div className={toSlidePrefix('content')}>
              <p className={toSlidePrefix('count')}>{`${images.length}/${i + 1}`}</p>
              <p className={toSlidePrefix('step')}>{steps[i]}</p>
            </div>
          </Slide>
        ))}
      </Slider>
    </Wrapper>
  )
}

export default RecipeSteps

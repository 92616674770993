import React from 'react'
import styled from 'styled-components'
import { toCssPrefix, showToast, hexToRGBA } from '@ecommerce/shared'
import copy from 'copy-to-clipboard'
import Alert from '../../Alert'
import { Icon } from '../../Icon/Icon'
import { generateLinks } from './utils'

type Props = {
  onClose: () => void
  message: string
  title: string
}

const { cssPrefix, toPrefix } = toCssPrefix('RecipeShareModal__')

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.black};

  .${cssPrefix} {
    &header {
      font-weight: bold;
      margin: 15px 0 25px;
      font-size: 17px;
    }

    &alert-background {
      align-items: center;
    }

    &alert {
      margin-top: 0;
    }

    &body {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &link {
      width: 45px;
      height: 45px;
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
      color: ${({ theme }) => theme.colors.black};
      background: ${({ theme }) => hexToRGBA(theme.colors.grey60, 0.5)};
      border-radius: 50%;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
`

const ShareModal = ({ onClose, message, title }: Props) => {
  const { mailToLink, facebookLink, twitterLink, whatsAppLink } = generateLinks(message)
  const commonAttributes = {
    target: '_blank',
    rel: 'noopener noreferrer',
  }

  const onCopyLink = () => {
    copy(window.location.href)
    showToast({ content: 'Enlace copiado', type: 'success' })
  }

  const ModalBody = () => (
    <div className={toPrefix('body')}>
      <a {...commonAttributes} title="Compartir en Facebook" href={facebookLink} className={toPrefix('link')}>
        <Icon iconId="facebook-outline" />
      </a>
      <a {...commonAttributes} title="Compartir en WhatsApp" href={whatsAppLink} className={toPrefix('link')}>
        <Icon iconId="whatsapp" />
      </a>
      <a {...commonAttributes} title="Compartir en Twitter" href={twitterLink} className={toPrefix('link')}>
        <Icon iconId="twitter" />
      </a>
      <button onClick={onCopyLink} title="Copiar enlace" type="button" className={toPrefix('link')}>
        <Icon iconId="link" />
      </button>
      <a {...commonAttributes} title="Compartir por correo" href={mailToLink} className={toPrefix('link')}>
        <Icon iconId="mail" />
      </a>
    </div>
  )

  return (
    <Wrapper className={cssPrefix}>
      <Alert
        className={toPrefix('alert')}
        backgroundClassName={toPrefix('alert-background')}
        onClose={onClose}
        header={<p className={toPrefix('header')}>{title}</p>}
        body={<ModalBody />}
      />
    </Wrapper>
  )
}

export default ShareModal

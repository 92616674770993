import React, { useState, useEffect } from 'react'
import { toCssPrefix, breakpoints, useShoppingCart, useAuth } from '@ecommerce/shared'
import styled, { ThemeProvider } from 'styled-components'
import { PageProps } from 'gatsby'
import { Recipe } from '../../graphql/contentfulTypes'
import Layout from '../../components/Layout'
import useTheme from '../../hooks/useTheme'
import Breadcrumbs from '../../components/Breadcrumbs'
import Seo from '../../components/Seo'
import SectionTitle from '../../components/Moments/SectionTitle'
import RecipeSteps from '../../components/Recipes/RecipeSteps'
import RecipeInfo from '../../components/Recipes/RecipeInfo'
import RelatedProductsList from '../../components/Recipes/RelatedProductsList'
import RecipesList from '../../components/Recipes/RecipesList'
import Video from '../../components/Video'
import ShareModal from '../../components/Recipes/ShareModal'
import { FlatLocationProductCategory, PgPageProps } from '../../types/PgPages'
import { sendPageViewEvent } from '../../utils/events'

const { cssPrefix, toPrefix } = toCssPrefix('RecipeDetail__')

type Props = PageProps<
  {},
  { data: Recipe; productCategories: FlatLocationProductCategory[] } & { themeId?: string } & PgPageProps['pageContext']
>

const Wrapper = styled.div<{
  backgroundImageBody?: string
  backgroundColorBody?: string
  backgroundColorCard?: string
  textColorCard?: string
}>`
  background: ${({ theme }) => theme.colors.background.bodyAlternative};
  color: ${({ theme }) => theme.colors.bodyText};

  .${cssPrefix} {
    &inner {
      max-width: 1360px;
      min-height: 100vh;
      margin: auto;
      position: relative;
    }

    &top {
      padding-top: 40px;
      position: relative;
      display: none;
    }

    &section-title {
      margin: 0 0 auto;
    }

    &breadcrumbs {
      width: auto;
      padding-left: 0;
      background: inherit;
      margin-bottom: 0;

      span {
        color: ${({ theme }) => theme.colors.bodyText};
      }
      button {
        color: ${({ theme }) => theme.colors.bodyText};
      }
    }

    &content {
      width: 100%;

      &-left {
        width: 100%;
        height: 300px;
      }

      &-right {
        background: ${({ backgroundColorCard, theme }) =>
          backgroundColorCard || theme.colors.productView.containerBackground || theme.colors.brand.default};
        box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};
        color: ${({ textColorCard, theme }) => textColorCard || theme.colors.bodyText};
        margin-top: 25px;
        padding: 55px 25px;
      }
    }

    &related-products {
      background-image: ${({ backgroundImageBody }) =>
        backgroundImageBody ? `url('${backgroundImageBody}')` : 'none'};
      background-size: cover;
      background-position: center;
      background-attachment: fixed;

      background-color: ${({ theme, backgroundColorBody }) =>
        backgroundColorBody || theme.colors.background.bodyAlternative};

      padding: 12px 0px 56px;
    }
  }

  @media screen and (${breakpoints.tabletLandscape.min}) {
    .${cssPrefix} {
      &content {
        &-left {
          height: 340px;
        }

        &-right {
          margin: 24px;
          padding: 40px;
        }
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    background-image: ${({ backgroundImageBody }) => (backgroundImageBody ? `url('${backgroundImageBody}')` : 'none')};
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

    background-color: ${({ theme, backgroundColorBody }) =>
      backgroundColorBody || theme.colors.background.bodyAlternative};

    padding: 0 80px;

    .${cssPrefix} {
      &top {
        display: block;
      }

      &breadcrumbs {
        z-index: 1;
        position: relative;
      }

      &content {
        margin-top: 60px;
        display: flex;
        box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};

        &-left {
          width: 50%;
          align-self: stretch;
          height: auto;
        }

        &-right {
          width: 50%;
          margin: 0;
          box-shadow: none;
          border-bottom-right-radius: ${({ theme }) => theme.borderRadius};
          border-top-right-radius: ${({ theme }) => theme.borderRadius};
        }
      }

      &related-products {
        background-color: ${({ theme }) => theme.colors.background.bodyAlternative};

        padding: 36px 0px 56px;
      }

      &related-recipes {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
`

const RecipeTemplate = ({ pageContext, location }: Props) => {
  const [showShareModal, setShowShareModal] = useState(false)

  const { data, productCategories } = pageContext

  const {
    parentMoment,
    metaTitle,
    metaKeywords,
    metaDescription,
    sliderImage,
    detailTitle,
    preparationVideo,
    preparationVideoPoster,
    preparationVideoTitle,
    preparationVideoDescription,
    steps,
    stepsImages,
    aboutTitle,
    aboutText,
    ingredientsTitle,
    ingredients,
    preparationTitle,
    preparationText,
    relatedProductsTitle,
    relatedProducts,
    relatedRecipesTitle,
    socialMediaShareMessage,
    backgroundColorCard,
    textColorCard,
    backgroundColorBody,
    backgroundImageBody,
  } = data
  const { mergeThemes } = useTheme(parentMoment?.theme?.contentful_id)

  const { getConnectifCart } = useShoppingCart()
  const { getEntityInfo } = useAuth()

  useEffect(() => {
    const entityInfo = getEntityInfo()
    const cart = getConnectifCart()
    sendPageViewEvent({ cart, entityInfo, event: 'watchRecipe', title: 'Recetas' })
  }, [])

  const breadcrumbs = [
    {
      name: 'Inicio',
      url: `/`,
    },
    {
      name: 'Mi bar',
      url: '/mi-bar',
    },
    {
      name: 'Recetas',
      url: '/mi-bar/recetas',
    },
    {
      name: detailTitle,
    },
  ]

  return (
    <ThemeProvider theme={mergeThemes}>
      <Layout navbarTitlte={parentMoment?.navbar?.title} navbarType={parentMoment?.navbar?.type} title={metaTitle}>
        <Wrapper
          backgroundImageBody={backgroundImageBody?.file.url}
          backgroundColorBody={backgroundColorBody}
          backgroundColorCard={backgroundColorCard}
          textColorCard={textColorCard}
          className={cssPrefix}
        >
          {showShareModal && (
            <ShareModal
              title="Comparte nuestra receta en:"
              message={socialMediaShareMessage}
              onClose={() => setShowShareModal(false)}
            />
          )}
          <div className={toPrefix('inner')}>
            <Seo keywords={metaKeywords} description={metaDescription ?? aboutText}>
              <meta property="og:type" content="website" />
              <meta name="twitter:card" content="summary" />
              <meta name="og:title" content={metaTitle} />
              <meta name="og:description" content={metaDescription ?? aboutText} />
              <meta name="og:image" content={sliderImage.file.url} />
              <meta name="twitter:image:alt" content={metaTitle} />
              <meta name="og:url" content={location.href} />
            </Seo>
            <div className={toPrefix('top')}>
              <Breadcrumbs
                items={breadcrumbs}
                className={toPrefix('breadcrumbs')}
                themeId={parentMoment?.theme?.contentful_id}
              />
              <SectionTitle className={toPrefix('section-title')} text={detailTitle} />
            </div>
            <div className={toPrefix('content')}>
              <div className={toPrefix('content-left')}>
                {preparationVideo?.file?.url ? (
                  <Video
                    preload="none"
                    title={preparationVideoTitle}
                    description={preparationVideoDescription}
                    poster={preparationVideoPoster?.file?.url}
                    src={preparationVideo.file.url}
                  />
                ) : (
                  <RecipeSteps steps={steps} images={stepsImages} />
                )}
              </div>
              <div className={toPrefix('content-right')}>
                <RecipeInfo
                  onShareClick={() => setShowShareModal(true)}
                  data={{ aboutText, aboutTitle, ingredients, ingredientsTitle, preparationTitle, preparationText }}
                />
              </div>
            </div>
            <RelatedProductsList
              className={toPrefix('related-products')}
              skus={relatedProducts.map(({ sku }) => sku)}
              title={relatedProductsTitle}
              themeId={parentMoment?.theme?.contentful_id}
              listName={metaTitle}
            />
            <RecipesList
              themeId={parentMoment?.theme?.contentful_id}
              className={toPrefix('related-recipes')}
              title={relatedRecipesTitle}
              listId=""
            />
          </div>
        </Wrapper>
      </Layout>
    </ThemeProvider>
  )
}

export default RecipeTemplate

import React from 'react'
import styled from 'styled-components'
import { toCssPrefix } from '@ecommerce/shared'
import { Icon } from '../Icon/Icon'

type Props = {
  iconId: 'cocktail' | 'ingredients' | 'mix'
  title: string
}

const { cssPrefix, toPrefix } = toCssPrefix('RecipeInfoTitle__')

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;

  .${cssPrefix} {
    &title {
      margin: 0;
      font-size: 24px;
      font-weight: bold;
      margin-left: 10px;
    }
  }
`

const InfoTitle = ({ iconId, title }: Props) => (
  <Wrapper className={cssPrefix}>
    <Icon className={toPrefix('icon')} iconId={iconId} />
    <p className={toPrefix('title')}>{title}</p>
  </Wrapper>
)

export default InfoTitle
